import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./additional-funding.css"
import {Link} from "gatsby"
import Foundation from "../data/af-foundations"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"

const AdditionalFundingFoundations = () => {
  const [foundations, setFoundations] = useState(Foundation.foundations)

  return (
    <Layout>
      <SEO title="Foundations, Grants, Non-Profits" />
      <div>
        <div className="container primary-bg">
          <div>
          <Link className="additonalFundingHeader"  to="/additional-funding">Available Funding Sources</Link>
          </div>
        <div>
          <label className="additonalFundingTitle">Foundations, Grants &amp; Non-Profits</label>
        </div>
          <hr />
          <section className="container">
            {foundations
              .sort((a, b) => (a.FoundationName > b.FoundationName ? 1 : -1))
              .map((foundation, index) => {
                return (
                  <div className="card">
            
                      <label className="additonalFundingCardTitle">{foundation.FoundationName}</label>
                      <div>
                        <FontAwesomeIcon
                          className="iconStyle"
                          size="lg"
                          icon={faGlobe}
                        />
                        <a
                          className="additonalFunding"
                          href={foundation.link}
                          target="_blank"
                        >
                          {foundation.link}
                        </a>
                      </div>
              
                  </div>
                )
              })}
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default AdditionalFundingFoundations
